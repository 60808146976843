import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

interface Toast {
  message: string;
  type: 'success' | 'error' | 'info';
}

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent {
  toasts: Toast[] = [];

  showToast(message: string, type: 'success' | 'error' | 'info' = 'info') {
    this.toasts.push({ message, type });

    // Masque le toast après 3 secondes
    setTimeout(() => this.removeToast(), 3000);
  }

  removeToast() {
    this.toasts.shift();
  }
}