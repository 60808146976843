import { Component, Inject, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AuthService } from '../../_services/auth/auth.service';
import { Observable } from 'rxjs';
import { CommonModule, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { initFlowbite } from 'flowbite';
import localeFr from '@angular/common/locales/fr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit{
  private as: AuthService = inject(AuthService);
  public isAuth: boolean = false;
  public isAdmin: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }
  
  ngOnInit(): void {
    this.as.isAuth$.subscribe((isAuth) => {
      this.isAuth = isAuth;
    });

    this.as.isAdmin$.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });
  }

  get isAuth$(): Observable<boolean> {
    return this.as.isAuth$;
  }

  get isAdmin$(): Observable<boolean> {
    return this.as.isAdmin$;
  }

  logout(){
    this.as.logout();
    this.router.navigateByUrl('/');
  }

  private router: Router = inject(Router);
  reload(){
    this.router.navigateByUrl('/login');
  }
}
