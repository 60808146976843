import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { AuthService } from '../../_services/auth/auth.service';
import { filter, first } from 'rxjs/operators';

export const AuthGuard: CanActivateFn = (route, state) => {
  const as = inject(AuthService);

  return as.isAuth$;
};
