<section class="container">
    <h2 class="pb-5 text-4xl lg:text-6xl text-blue-dark font-alice font-medium">Inscription à un événement</h2>
    <div>
        <form [formGroup]="inscriptionForm" (ngSubmit)="validate()" class="flex flex-col gap-4">
            <div>
                <label for="evenement_key" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Événement</label>
                <select formControlName="evenement_key" id="evenement_key" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    @for(evenement of evenements; track $index) {
                        <option value="{{ evenement.key }}">{{ evenement.nom }}</option>
                    }
                </select>
            </div>
            <div>
                <label for="utilisateur_uid" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Utilisateur</label>
                <select formControlName="utilisateur_uid" id="utilisateur_uid" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    @for(utilisateur of utilisateurs; track $index) {
                        <option value="{{ utilisateur.uid }}">{{ utilisateur.lastName }} {{ utilisateur.firstName }}</option>
                    }
                </select>
            </div>
            <div class="flex items-center">
                <input type="checkbox" id="is_present" formControlName="is_present" name="present" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                <label for="is_present" class="ml-2 block text-sm text-gray-900">Elle ou il sera présent.e</label>
              </div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Valider</button>
        </form>
    </div>
</section>

<section class="container py-10">
    <h2 class="pb-5 text-4xl lg:text-6xl text-blue-dark font-alice font-medium">Listes des Utilisateurs</h2>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead class="text-xs text-gray-700 uppercase">
                <tr>
                    <th scope="col" class="px-6 py-3 bg-gray-50">
                        Nom
                    </th>
                    <th scope="col" class="px-6 py-3 bg-gray-50">
                        Prénom
                    </th>
                    <th scope="col" class="px-6 py-3 bg-gray-50">
                        Email
                    </th>
                    <!-- <th scope="col" class="px-6 py-3 bg-gray-50">
                        Actions
                    </th> -->
                </tr>
            </thead>
            <tbody>
                @for (utilisateur of utilisateurs; track $index) {
                    <tr class="border-b border-gray-200">
                        <td class="px-6 py-4">
                            {{ utilisateur.lastName }}
                        </td>
                        <td class="px-6 py-4">
                            {{ utilisateur.firstName }}
                        </td>
                        <td class="px-6 py-4">
                            {{ utilisateur.email }}
                        </td>
                        <!-- <td class="px-6 py-4">
                        </td> -->
                    </tr>
                }
            </tbody>
        </table>
    </div>
</section>

<app-toast></app-toast>