<section class="container flex flex-col gap-4">
  <h2 class="text-center text-4xl font-extrabold hammersmith-one-regular uppercase">evenements</h2>
  <div class="container relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="checkbox-all-search" class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            Nom
          </th>
          <th scope="col" class="px-6 py-3">
            Date de début
          </th>
          <th scope="col" class="px-6 py-3">
            Date de fin
          </th>
          <th scope="col" class="px-6 py-3">
            Lieu
          </th>
          <th scope="col" class="px-6 py-3">
            Private
          </th>
          <!-- <th scope="col" class="px-6 py-3">
            Nombre de réponse
          </th>
          <th scope="col" class="px-6 py-3">
            Nombre de présent
          </th>
          <th scope="col" class="px-6 py-3">
            Jours restant
          </th> -->
          <th scope="col" class="px-6 py-3">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let evenement of evenements">
          <td class="w-4 p-4">
            <div class="flex items-center">
              <input id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
            </div>
          </td>
          <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <a href="/agenda/{{ evenement.key }}" target="_blank" class="flex flex-row gap-1 items-center">
              <span>{{ evenement.nom }}</span>
              <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
              </svg>
            </a>
          </td>
          <td class="px-6 py-4">
            {{ evenement.dateDebut | date: 'dd/MM/yyyy'}}
          </td>
          <td class="px-6 py-4">
            {{ evenement.dateFin | date: 'dd/MM/yyyy' }}
          </td>
          <td class="px-6 py-4">
            {{ evenement.lieu }}
          </td>
          <td class="px-6 py-4">
            {{ evenement.private }}
          </td>
          <!-- <td class="px-6 py-4">
            N/A
          </td>
          <td class="px-6 py-4">
            N/A
          </td>
          <td class="px-6 py-4">
            N/A
          </td> -->
          <td class="flex items-center px-6 py-4">
            <a (click)="onEdit(evenement)" class="cursor-pointer font-medium text-blue-600 hover:underline">Modifier</a>
            <a (click)="onDelete(evenement)"  class="cursor-pointer font-medium text-red-600 hover:underline ms-3">Supprimer</a>
            <!-- <a class="font-medium text-gray-200 ms-3">Supprimer</a> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="container relative overflow-x-auto shadow-md sm:rounded-lg p-4">
      <form [formGroup]="evenementForm" (ngSubmit)="onSubmit()" class="w-full bg-white flex flex-col gap-2">
        <div>
          <label for="nom" class="block text-sm font-medium text-gray-700">Nom :</label>
          <input type="text" id="nom" formControlName="nom" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
        </div>
        <div>
          <label for="description" class="block text-sm font-medium text-gray-700">Description :</label>
          <textarea id="description" formControlName="description" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"></textarea>
        </div>
        <div class="grid grid-cols-2 gap-2">
          <div>
            <label for="dateDebut" class="block text-sm font-medium text-gray-700">Date de début :</label>
            <input type="date" id="dateDebut" formControlName="dateDebut" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          </div>
          <div>
            <label for="heureDebut" class="block text-sm font-medium text-gray-700">Heure de début :</label>
            <input type="time" id="heureDebut" formControlName="heureDebut" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2">
          <div>
            <label for="dateFin" class="block text-sm font-medium text-gray-700">Date de fin :</label>
            <input type="date" id="dateFin" formControlName="dateFin" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          </div>
          <div>
            <label for="heureFin" class="block text-sm font-medium text-gray-700">Heure de fin :</label>
            <input type="time" id="heureFin" formControlName="heureFin" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          </div>
        </div>
        <div>
          <label for="lieu" class="block text-sm font-medium text-gray-700">Lieu :</label>
          <input type="text" id="lieu" formControlName="lieu" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
        </div>
        <div>
          <label for="image" class="block text-sm font-medium text-gray-700">Image (URL) :</label>
          <input type="text" id="image" formControlName="image" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
        </div>
        <div class="flex items-center">
          <input type="checkbox" id="isPrivate" formControlName="isPrivate" name="private" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
          <label for="isPrivate" checked class="ml-2 block text-sm text-gray-900">Evenement Privé</label>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Soumettre</button>
          <button type="button" (click)="onReset()" class="w-full flex justify-center py-2 px-4 border-transparent rounded-md shadow-sm text-sm font-medium border-2 border-red-600 text-red-600 hover:text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Annuler</button>
        </div>
        
      </form>
  </div>
</section>

