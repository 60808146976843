import { Component, inject, ViewChild } from '@angular/core';
import { AuthService } from '../../../_services/auth/auth.service';
import { UtilisateurService } from '../../../_services/utilisateur/utilisateur.service';
import { Utilisateur } from '../../../_interfaces/utilisateur';
import { Inscription } from '../../../_interfaces/inscription';
import { EvenementService } from '../../../_services/evenement/evenement.service';
import { Evenement } from '../../../_interfaces/evenement';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InscriptionService } from '../../../_services/inscription/inscription.service';
import { ToastComponent } from '../../../_components/toast/toast.component';

@Component({
  selector: 'app-utilisateur-edition',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ToastComponent],
  templateUrl: './utilisateur-edition.component.html',
  styleUrl: './utilisateur-edition.component.scss'
})
export class UtilisateurEditionComponent {
  private utilisateurService: UtilisateurService = inject(UtilisateurService);
  private evenementService: EvenementService = inject(EvenementService);
  private insciptionService: InscriptionService = inject(InscriptionService);
  private formBuilder: FormBuilder = inject(FormBuilder);

  @ViewChild(ToastComponent) toast!: ToastComponent;
  
  public utilisateurs: Utilisateur[] = []; 
  public evenements: Evenement[] = [];
  public event_id: string = '';
  public inscriptionForm: FormGroup;

  constructor() {
    this.getUtilisateurs()
    this.getEvenements()
    this.inscriptionForm = this.formBuilder.group({
      utilisateur_uid: [''],
      evenement_key: [''],
      is_present: [''],
    });
  }

  private getUtilisateurs() {
    this.utilisateurService.readAllUtilisateurs().subscribe(utilisateurs => {
      this.utilisateurs = utilisateurs.sort((a, b) => {
        return a.lastName.localeCompare(b.lastName);
      })
    });
  }

  private getEvenements(){
    this.evenementService.readAllFutureEvenements().subscribe(evenements => {
      this.evenements = evenements;
    });
  }

  public validate(){
    this.insciptionService.createInscription(
      this.inscriptionForm.value.utilisateur_uid,
      this.inscriptionForm.value.evenement_key,
      this.inscriptionForm.value.is_present).then(() => {});
    this.showToast('Inscription créée', 'success');
    this.inscriptionForm.reset();
  }

  showToast(str: string, type: 'success' | 'error' | 'info' = 'info') {
    this.toast.showToast(str, type);
  }
}
