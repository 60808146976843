import { Component, OnInit, Renderer2, ViewChild, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { EvenementService } from '../../_services/evenement/evenement.service';
import { Evenement } from '../../_interfaces/evenement';
import { TruncatePipe } from '../../_pipes/truncate/truncate.pipe';
import { CommonModule } from '@angular/common';
import { ToastComponent } from '../../_components/toast/toast.component';
import { FormsModule } from '@angular/forms';
import { NewsletterService } from '../../_services/newsletter/newsletter.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [TruncatePipe, CommonModule, ToastComponent, FormsModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  constructor(
    private metaService: Meta,
    private titleService: Title) {}

  @ViewChild(ToastComponent) toast!: ToastComponent;

  evenementService = inject(EvenementService);
  newsletterService = inject(NewsletterService);
  carouselElement!: HTMLElement;
  email!: string;

  evenements: Evenement[] = [];

  ngOnInit(){
    this.updateMeta()
    this.retrieve_evenements()
  }

  updateMeta(){
    // Définir le titre de la page
    this.titleService.setTitle('La Bourrée de Paris');

    // Définir les balises meta pour SEO
    this.metaService.addTags([
      { name: 'description', content: "La Bourrée de Paris : groupe folklorique auvergnat à Paris, offrant danse, musique et chants traditionnels depuis 1925 pour tous types d'événements." },
      { name: 'keywords', content: 'groupe folklorique, traditions auvergnates, danse traditionnelle, musique auvergnate, chant auvergnat, La Bourrée de Paris, spectacle folklorique, groupe de folklore Paris, patrimoine culturel, animation événementielle' },
      { name: 'robots', content: 'index,follow' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'La Bourrée de Paris' },
      { property: 'og:description', content: 'Rejoignez-nous pour une expérience immersive dans la tradition auvergnate avec La Bourrée de Paris, offrant des spectacles de danse, musique et chant depuis 1925.' },
      { property: 'og:image', content: 'assets/images/photo-4.webp' },
      { property: 'og:url', content: 'https://www.labourree.com' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'La Bourrée de Paris' },
      { name: 'twitter:description', content: 'Rejoignez-nous pour une expérience immersive dans la tradition auvergnate avec La Bourrée de Paris, offrant des spectacles de danse, musique et chant depuis 1925.' },
      { name: 'twitter:image', content: 'assets/images/photo-4.webp' }
      // Plus d'autres balises que vous pourriez vouloir ajouter
    ]);
  }

  retrieve_evenements(){
    // Récupérer les événements
    this.evenementService.readXEvenements(3).subscribe(evenements => {
      this.evenements = evenements;
    });
  }

  onNewsletterSubmit() {
    if(this.email) {
      this.showToast(`Merci de vous être inscrit à la newsletter avec l'adresse ${this.email}`, 'success');
      this.newsletterService.createNewsletter(this.email);
    }
    this.email = '';
  }

  showToast(str: string, type: 'success' | 'error' | 'info' = 'info') {
    this.toast.showToast(str, type);
  }
}
