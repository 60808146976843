import { Injectable, inject } from '@angular/core';
// import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { Database, DatabaseReference, ref, list, push, object, get, query, orderByChild, equalTo } from '@angular/fire/database';
import { Observable, first, from, map } from 'rxjs';
import { Utilisateur } from '../../_interfaces/utilisateur';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {
  utilisateursRef: DatabaseReference;

  constructor(private database: Database) {
    this.utilisateursRef = ref(this.database, '/utilisateurs')
  }

  readAllUtilisateurs(): Observable<Utilisateur[]>{
    return list(this.utilisateursRef).pipe(
      map(
        queryChanges => queryChanges.map(
          change => ({ key: change.snapshot.key, ...change.snapshot.val() })
        )
      )
    ) as Observable<Utilisateur[]>;
  }

  readUtilisateurByUid(uid: string): Observable<Utilisateur> {
    const queryRef = query(this.utilisateursRef, orderByChild('uid'), equalTo(uid));
    
    return from(get(queryRef)).pipe(
      map(snapshot => {
        if (snapshot.exists()) {
          const users = snapshot.val();
          const keys = Object.keys(users);
          if (keys.length > 0) {
            const key = keys[0]; // Prendre le premier utilisateur trouvé
            return { ...users[key], key } as Utilisateur;
          }
        }
        throw new Error(`Aucun utilisateur trouvé avec l'UID: ${uid}`);
      })
    );
  }

  utilisateurExistWithMail(email: string): Observable<Boolean> {
    const queryRef = query(this.utilisateursRef, orderByChild('email'), equalTo(email));
    return from(get(queryRef)).pipe(
      map(snapshot => {
        return snapshot.exists();
      })
    );
  }

  createUtilisateur(utilisateur : Utilisateur){
    return push(this.utilisateursRef, {
      email: utilisateur.email,
      firstName: utilisateur.firstName,
      lastName: utilisateur.lastName,
      uid: utilisateur.uid,
      role: utilisateur.role
    });  
  }
}
