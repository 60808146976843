import { inject, Injectable } from '@angular/core';
import { Database, DatabaseReference, list, push, Query, query, ref, set } from '@angular/fire/database';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  private EMAILS_PATH = '/emails';
  private email: DatabaseReference;
  private database: Database = inject(Database);

  constructor() {
    this.email = ref(this.database, this.EMAILS_PATH);
  }

  createNewsletter(email: string) {
    const newEmailRef = push(this.email)
    return set(newEmailRef, email);
  }

  readAllNewsletters() {
    const emailsQuery = query(this.email);
    return this.readQuerry(emailsQuery);
  }

  private readQuerry(query: Query): Observable<string[]> {
    return list(query).pipe(
      map(
        queryChanges => queryChanges.map(
          change => change.snapshot.val() as string
        )
      )
    );
  }
}
