import { Component, inject, ViewChild } from '@angular/core';
import { AuthService } from '../../_services/auth/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs';
import { ToastComponent } from '../../_components/toast/toast.component';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule, ToastComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  private as: AuthService = inject(AuthService);
  private router: Router = inject(Router);

  @ViewChild(ToastComponent) toast!: ToastComponent;

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor() {}

  ngOnInit(): void {}

  login() {
    const formValues = this.loginForm.value;
    const email = formValues.email;
    const password = formValues.password;
    if (email && password) {
      this.as.login(email, password).then(
        (isGood) => {
          if(isGood) {
            this.router.navigateByUrl('/');
          }else{
            this.showToast('Email or password is incorrect', 'error');
            this.loginForm.reset();
          }
      });
    } else {
      this.showToast('Please fill all fields', 'error');
    }
  }

  showToast(str: string, type: 'success' | 'error' | 'info' = 'info') {
    this.toast.showToast(str, type);
  }

  resetPassword() {
    const email = this.loginForm.value.email;
    if (email) {
      this.as.resetPassword(email).then(
        () => {
          this.showToast('Un e-mail de réinitialisation à été envoyé', 'success');
          this.loginForm.reset();
        },
        (error) => {
          this.showToast(error.message, 'error');
        }
      );
    } else {
      this.showToast('Remplissez le champ "Email"', 'error');
    }
  }

  logout(){
    this.as.logout();
  }
}
