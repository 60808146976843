<!-- Affiche app-navbar si l'URL n'est ni /login, ni /register, ni une route admin -->
<app-navbar [hidden]="isLoginOrRegister() || isAdmin()"></app-navbar>

<!-- Affiche app-admin-navbar si l'URL est une route admin -->
<app-admin-navbar [hidden]="!isAdmin()"></app-admin-navbar>

<!-- Router outlet unique pour toutes les routes -->
<div [ngClass]="{'p-4 sm:ml-64': isAdmin()}">
    <router-outlet/>
    <app-footer [hidden]="isLoginOrRegister()"></app-footer>
</div>