import { Injectable, inject } from '@angular/core';
import { DatabaseReference, get, push, ref, query, orderByChild, equalTo, update, child } from 'firebase/database';
import { AuthService } from '../auth/auth.service';
import { Database } from '@angular/fire/database';
import { Utilisateur } from '../../_interfaces/utilisateur';
import { Inscription } from '../../_interfaces/inscription';
import { UtilisateurService } from '../utilisateur/utilisateur.service';
import { first, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InscriptionService {
  inscriptionsRef: DatabaseReference;
  private database_name: string = '/inscriptions';
  
  private authService: AuthService = inject(AuthService);
  private database: Database = inject(Database);
  private utilisateurService: UtilisateurService = inject(UtilisateurService);
  
  private meUid: string | null = null;
  private me: Utilisateur | null = null;
  
  constructor() {
    this.inscriptionsRef = ref(this.database, this.database_name);
    this.authService.currentUid$.subscribe((uid) => {
      this.meUid = uid;
    });
    this.authService.currentUser$.subscribe((user) => {
      this.me = user;
    });
  }
  
  readAllInscriptionOfEvenement(key: string): Promise<Inscription[]> {
    const queryRef = query(
      this.inscriptionsRef,
      orderByChild('evenement_key'),
      equalTo(key)
    );
    
    return get(queryRef).then(snapshot => {
      if (snapshot.exists()) {
        return Object.values(snapshot.val()) as Inscription[];
      }
      return [];
    });
  }
  
  readOneInscriptionOfMeAndEvenement(evenement_key: string | null): Promise<Inscription | undefined> {
    if (!evenement_key) {
      return Promise.reject(new Error('Impossible de lire une inscription sans clé d\'événement'));
    }
    if (!this.meUid) {
      return Promise.reject(new Error('Impossible de lire les inscriptions sans être connecté'));
    }
    
    const composite_key = `${this.meUid}_${evenement_key}`;
    return this.readInscriptionByCompositeKey(composite_key);
  }
  
  readInscriptionByCompositeKey(composite_key: string): Promise<Inscription | undefined> {
    const queryRef = query(
      this.inscriptionsRef,
      orderByChild('composite_key'),
      equalTo(composite_key)
    );
    
    return get(queryRef).then(snapshot => {
      if (snapshot.exists()) {
        const inscriptions = snapshot.val();
        const keys = Object.keys(inscriptions);
        if (keys.length > 0) {
          const key = keys[0]; // Prendre la première inscription trouvée
          return { ...inscriptions[key], key } as Inscription;
        }
      }
      return undefined;
    });
  }

  async createInscription(utilisateur_id: string, evenement_key: string, is_present: boolean) {
    try {
      if(!this.meUid) {
        throw new Error('Impossible de créer une inscription sans être connecté');
      }
      const user_id = (utilisateur_id ? utilisateur_id : this.meUid);
      const utilisateur = await firstValueFrom(this.utilisateurService.readUtilisateurByUid(user_id));
      
      const composite_key = `${user_id}_${evenement_key}`;
      const inscription: Inscription = {
        composite_key: composite_key,
        nom_prenom: `${utilisateur.lastName} ${utilisateur.firstName}`,
        evenement_key: evenement_key,
        utilisateur_uid: user_id,
        first_date: new Date().toISOString(),
        last_update: new Date().toISOString(),
        is_present: is_present,
        commentaire: "",
        key: null
      };
  
      const inscriptionFound = await this.readInscriptionByCompositeKey(composite_key);
      
      if (!inscriptionFound) {
        // Aucune inscription existante trouvée, créer une nouvelle
        const ref = await push(this.inscriptionsRef, inscription);
        return { ...inscription, key: ref.key };
      } else {
        // Inscription existante trouvée, mise à jour de l'inscription
        inscription.key = inscriptionFound.key;
        inscription.first_date = inscriptionFound.first_date;
        await this.updateInscription(inscription);
        return inscription;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  updateInscription(inscription: Inscription): Promise<void> {
    if (inscription.key == null) {
      return Promise.reject('La clé de l\'inscription est nulle');
    }
    
    const inscriptionRef = child(this.inscriptionsRef, inscription.key);
    const newInscriptionData = {
      last_update: new Date().toISOString(),
      is_present: inscription.is_present,
      commentaire: inscription.commentaire
    };
    
    return update(inscriptionRef, newInscriptionData);
  }
  
  async getEmailsOfUsersPresentAtEvent(eventKey: string): Promise<string[]> {
    const inscriptions = await this.readAllInscriptionOfEvenement(eventKey);
    const userKeysPresent = inscriptions
    .filter(inscription => inscription.is_present)
    .map(inscription => inscription.utilisateur_uid);
    
    const emailPromises = userKeysPresent.map(uid => 
      firstValueFrom(this.utilisateurService.readUtilisateurByUid(uid))
    );
    
    const utilisateurs = await Promise.all(emailPromises);
    
    return utilisateurs
    .filter((utilisateur): utilisateur is Utilisateur => utilisateur != null)
    .map(utilisateur => utilisateur ? utilisateur.email : null)
    .filter(email => email != null) as string[];
  }
  
  async getEmailsOfUsersNotInscribedToEvent(eventKey: string): Promise<string[]> {
    const allUtilisateurs$ = this.utilisateurService.readAllUtilisateurs().pipe(first());
    const allUtilisateurs = await firstValueFrom(allUtilisateurs$);
    
    if (!allUtilisateurs) {
      // Gérer le cas où aucun utilisateur n'est retourné
      return [];
    }
    
    const inscriptions = await this.readAllInscriptionOfEvenement(eventKey);
    const userKeysInEvent = new Set(inscriptions.map(inscription => inscription.utilisateur_uid));
    
    const notInscribedEmails = allUtilisateurs
    .filter(utilisateur => !userKeysInEvent.has(utilisateur.uid))
    .map(utilisateur => utilisateur.email);
    
    return notInscribedEmails;
  }
}
