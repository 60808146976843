import { Injectable, inject } from '@angular/core';
import { Observable, defer, map, of, switchMap } from 'rxjs';
import { Utilisateur } from '../../_interfaces/utilisateur';
import { Auth, UserCredential, signInWithEmailAndPassword, createUserWithEmailAndPassword , signOut, User, authState, sendPasswordResetEmail } from '@angular/fire/auth';
import { UtilisateurService } from '../utilisateur/utilisateur.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth: Auth = inject(Auth)
  private utilisateurService: UtilisateurService = inject(UtilisateurService);

  // public isAuth$ = authState(this.auth).pipe(map((user: any) => {return user !== null}));
  public isAuth$ = defer(() => authState(this.auth).pipe(map((user: any) => user !== null)));
  public isAdmin$: Observable<boolean> = defer(() => authState(this.auth).pipe(
    switchMap(
      (user: any) => {
        if(user != null){
          return this.utilisateurService.readUtilisateurByUid(user.uid).pipe(
            map((utilisateur: Utilisateur) => {
              return (utilisateur.role === 'admin') as boolean;
            })
          );
        }else{
          return of(false);
        }
      }
    ))
  );
  public currentUid$ = authState(this.auth).pipe(map((user: any) => {return user?.uid}));
  public currentUser$: Observable<Utilisateur | null> = authState(this.auth).pipe(
    switchMap((user: any) => {
      if (user) {
        return this.utilisateurService.readUtilisateurByUid(user.uid);
      } else {
        return of(null); // Retourner un observable null si aucun utilisateur n'est authentifié
      }
    })
  );
  
  constructor(){}

  login(email: string, password: string): Promise<boolean>{
    return signInWithEmailAndPassword(this.auth, email, password)
    .then((userCredential: UserCredential) => {
      return true;
    }).catch((error) => {
      return false;
    });
  }

  logout(){
    signOut(this.auth).then(() => {
    }).catch((error) => {
      console.error(error);
    });
  }

  register(email: string, password: string, firstName: string, lastName: string): Promise<boolean>{
    //Verifier si le mail est pas deja utilisé
    if(this.utilisateurService.utilisateurExistWithMail(email)){
      return createUserWithEmailAndPassword(this.auth, email, password).then((userCredential: UserCredential) => {
        const user = userCredential.user;
        return this.utilisateurService.createUtilisateur({
          uid: user.uid,
          email: email,
          firstName: firstName,
          lastName: lastName,
          role: 'user'
        }).then(() => true);
      }).catch((error) => {
        console.error(error);
        return false;
      });
    }else{
      return new Promise((resolve, reject) => {
        resolve(false);
      });
    }
  }

  async isLogged(){
    return await authState(this.auth);
  }

  resetPassword(email: string): Promise<void>{
    return sendPasswordResetEmail(this.auth, email);
  }
}