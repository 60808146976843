export const environment = {
    production: false,
    firebase : {
        apiKey: "AIzaSyBwQrLRlz2ADNF1gfROZhAzO0RZIlSSOFc",
        authDomain: "site-bdp-dev.firebaseapp.com",
        databaseURL: "https://site-bdp-dev-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "site-bdp-dev",
        storageBucket: "site-bdp-dev.appspot.com",
        messagingSenderId: "292325516207",
        appId: "1:292325516207:web:d2a39a8dc3006141f0055a",
        measurementId: "G-HD0EER15FF"
    }
};