<div class="bg-neutral-50">
    <div class="container p-5 flex flex-col gap-2 md:gap-4">
        <div class="grid grid-cols-1 gap-4">
            <div class="flex space-x-2">
                <a href="/agenda" class="flex flex-row gap-1 md:w-auto text-center px-6 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg class="w-4 h-4 m-auto text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
                      </svg>
                      Revenir à l'agenda
                </a>
            </div>
            <div class="grid grid-cols-1 gap-2 md:w-1/2 md:mx-auto">
                <h1 class="text-neutral-900 text-center text-4xl md:text-5xl font-extrabold hammersmith-one-regular">{{ evenement.nom }}</h1>
                <p class="uppercase text-neutral-600 text-center md:text-2xl font-medium">du {{ evenement.dateDebut | date:"EEE d MMM (HH'h'mm)": 'Europe/Paris': 'fr-FR' }} au {{ evenement.dateFin | date:"EEE d MMM (HH'h'mm)": 'Europe/Paris': 'fr-FR' }}</p>
                <div class="grid grid-cols-2 gap-4 md:w-1/2 md:mx-auto">
                    @if(evenement.key){
                        <button (click)="setInscription(evenement.key, true)" class="p-2 rounded-full border-2 text-center"
                        [ngClass]="{'text-white bg-green-500 hover:bg-green-600' : inscription && inscription.is_present,
                                    'text-green-500 bg-transparent hover:bg-transparent border-green-400': (inscription && !inscription.is_present) || !inscription}">
                            <svg class="h-5 w-5 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                        </button>
                        <button (click)="setInscription(evenement.key, false)" class="p-2 rounded-full border-2"
                        [ngClass]="{'text-white bg-red-500 hover:bg-red-600' : inscription && !inscription.is_present,
                                    'text-red-500 bg-transparent hover:bg-transparent border-red-400': (inscription && inscription.is_present) || !inscription}">
                            <svg class="h-5 w-5 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                    }
                </div>
            </div>
            
            <div class="md:w-2/3 md:mx-auto max-h-[50vh]">
                <!-- Image -->
                @if(evenement.image){
                    <img class="w-full h-full object-cover aspect-video" src="{{ evenement.image }}" alt="Image de l'événement">
                }
                @else{
                    <img class="w-full h-full object-cover aspect-video" src="assets/images/photo-1.jpg" alt="Image de l'événement">
                }
            </div>
            <div class="md:w-2/3 md:mx-auto" *ngIf="evenement.description">
                <h2 class="text-neutral-900 text-2xl md:text-3xl font-extrabold hammersmith-one-regular text-left">Description</h2>
                <p class="text-neutral-900">{{ evenement.description }}</p>
            </div>
            <div class="md:w-2/3 md:mx-auto" *ngIf="evenement.lieu">
                <h2 class="text-neutral-900 text-2xl md:text-3xl font-extrabold hammersmith-one-regular text-left">Lieu</h2>
                <p class="text-neutral-900">{{ evenement.lieu }}</p>
            </div>
        </div>
        <div class="md:w-2/3 md:mx-auto flex flex-col gap-4">
            <h2 class="text-neutral-900 text-2xl md:text-3xl font-extrabold hammersmith-one-regular text-left">Inscriptions</h2>
            <div class="flex flex-row gap-4">
                <span class="p-2 px-4 bg-green-200 rounded-full">Présents : {{ nbPresent }}</span>
                <span class="p-2 px-4 bg-red-200 rounded-full">Non présents : {{ nbNonPresent }}</span>
            </div>
            <div class="container mx-auto">
                <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="py-3 px-6">Prenom Nom</th>
                                <th scope="col" class="py-3 px-6">Statut de Présence</th>
                                <!-- <th scope="col" class="py-3 px-6">Commentaire</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            @for (inscription of inscriptions; track $index) {
                                <!-- Boucle sur les données ici -->
                                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td class="py-4 px-6">{{ inscription.nom_prenom }}</td>
                                    <td class="py-4 px-6">
                                        @if(inscription.is_present){Présent}@else{Non Présent}
                                    </td>
                                    <!-- <td class="py-4 px-6">{{ inscription.commentaire }}</td> -->
                                </tr>
                                <!-- Fin de la boucle -->
                            }
                        </tbody>
                    </table>
                </div>
                @if(isAdmin$ | async){
                <div class="flex justify-end space-x-2 mt-4">
                        <!-- Envoyer Mail (Présents) -->
                        @if(mailto_present === undefined){
                            <a class="px-6 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                </div>
                            </a>
                        }
                        @else {
                            <a href="{{ mailto_present }}" class="px-6 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Envoyer Mail (Présents)
                            </a>
                        }
        
                        @if(mailto_pas_repondu === undefined){
                            <a class="px-6 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                </div>
                            </a>
                        }
                        @else {
                            <a href="{{ mailto_pas_repondu }}" class="px-6 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Relancer (Non-Répondus)
                            </a>
                        }
                </div>
                }
            </div>
        </div>
    </div>
</div>
