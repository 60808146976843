import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { AuthService } from '../../_services/auth/auth.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastComponent } from '../../_components/toast/toast.component';
import { After } from 'v8';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [ReactiveFormsModule, ToastComponent],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {
  private as: AuthService = inject(AuthService);
  private router: Router = inject(Router);
  private goodCode = '1215';

  @ViewChild(ToastComponent) toast!: ToastComponent;

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    code: new FormControl(''),
  });

  constructor() {}

  normalizeName(name: string | null | undefined):  string | null | undefined {
    const particles = ["de", "du", "des", "la", "le", "les", "de la"];
    if(!name) return name;
    return name
        .split(/([ -])/)
        .map((part, index, array) => {
            const lowerPart = part.toLowerCase();
            // Garder les particules en minuscules
            if (particles.includes(lowerPart)) {
                return lowerPart;
            }
            // Sinon, mettre une majuscule au début
            return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        })
        .join('');
  }

  register() {
    const formValues = this.loginForm.value;
    const email = formValues.email;
    const password = formValues.password;
    const firstName = this.normalizeName(formValues.firstName);
    const lastName = this.normalizeName(formValues.lastName);
    const code = formValues.code;
    if (email && password && firstName && lastName && code) {
      if(code === this.goodCode){
        this.as.register(email, password, firstName, lastName).then(
          (isGood) => {
            if(isGood) {
              this.router.navigate(['/']);
            }else{
              this.showToast('Mauvaise info ou le mail est déja utilisé', 'error');
              // this.loginForm.reset();
            }
        });
      }else{
        this.showToast('Ce n\'est surement pas le bon code', 'error');
      }
    } else {
      this.showToast('Vous devez remplir tous les champs', 'error');
    }
  }

  showToast(str: string, type: 'success' | 'error' | 'info' = 'info') {
    this.toast.showToast(str, type);
  }
}
