<footer class="bg-white">
    <div class="p-4 container">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
            <div class="text-sm text-gray-500 text-center md:text-left">
                © 1925-2025 <a href="https://labourree.com/" class="hover:underline font-bold">La Bourrée de Paris</a>.
                Developed by <a href="https://corentin-drault.fr/" class="hover:underline font-bold">Corentin Drault</a>
            </div>
            <div class="text-sm text-gray-500 text-center font-medium md:text-right p-2">
                <a *ngIf="isAuth$ | async" (click)="logout()" class="hover:underline cursor-pointer p-2 rounded-lg border-gray-500 border">Se déconnecter</a>
                <a *ngIf="!(isAuth$ | async)" href="/login" class="hover:underline p-2 rounded-lg border-gray-500 border">Se connecter à l'intranet</a>
            </div>
        </div>
    </div>    
</footer>