import { Component } from '@angular/core';

@Component({
  selector: 'app-ressources',
  standalone: true,
  imports: [],
  templateUrl: './ressources.component.html',
  styleUrl: './ressources.component.scss'
})
export class RessourcesComponent {

}
