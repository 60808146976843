import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AuthService } from '../../_services/auth/auth.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  private as: AuthService = inject(AuthService);
  private router: Router = inject(Router);
  
  get isAuth$(): Observable<boolean> {
    return this.as.isAuth$;
  }

  logout(){
    this.as.logout();
    this.router.navigateByUrl('/');
  }
}
