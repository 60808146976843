import { Component, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EvenementService } from '../../../_services/evenement/evenement.service';
import { Evenement } from '../../../_interfaces/evenement';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-evenement-edition',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './evenement-edition.component.html',
  styleUrl: './evenement-edition.component.scss'
})
export class EvenementEditionComponent {
  private evenementService: EvenementService = inject(EvenementService)
  evenementForm: FormGroup;
  evenement?: Evenement;

  evenements: Evenement[] = [];

  constructor(private fb: FormBuilder) {
    this.evenementForm = this.fb.group(
      {
        nom: ['', Validators.required],
        description: [''],
        dateDebut: ['', Validators.required],
        dateFin: ['', Validators.required],
        heureDebut: ['', Validators.required],
        heureFin: ['', Validators.required],
        lieu: [''],
        image: [''],
        isPrivate: true
      },
      { 
        validators: this.dateRangeValidator 
      }
    );
    this.retrieve_evenements();
  }

  dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;
    const dateDebut = formGroup.get('dateDebut')?.value;
    const dateFin = formGroup.get('dateFin')?.value;
    return dateDebut && dateFin && new Date(dateDebut) > new Date(dateFin) ? { 'dateRangeInvalid': true } : null;
  };

  onSubmit() {
    if (this.evenementForm.valid){
      const dateDebutValue = this.evenementForm.get('dateDebut')?.value;
      const heureDebutValue = this.evenementForm.get('heureDebut')?.value;

      const dateDebut = new Date(dateDebutValue);
      const [heure, minute] = heureDebutValue.split(':');
      dateDebut.setHours(parseInt(heure, 10), parseInt(minute, 10));

      const dateFinValue = this.evenementForm.get('dateFin')?.value;
      const heureFinValue = this.evenementForm.get('heureFin')?.value;

      const dateFin = new Date(dateFinValue, );
      const [heureFin, minuteFin] = heureFinValue.split(':');
      dateFin.setHours(parseInt(heureFin, 10), parseInt(minuteFin, 10));


      const evenement: Evenement = {
        nom: this.evenementForm.get('nom')?.value,
        description: this.evenementForm.get('description')?.value,
        dateDebut: dateDebut.toString(),
        dateFin: dateFin.toString(),
        lieu: this.evenementForm.get('lieu')?.value,
        image: this.evenementForm.get('image')?.value,
        private: this.evenementForm.get('isPrivate')?.value,
        key: null
      }

      if (this.evenement && this.evenement.key) {
        this.evenementService.updateEvenement(this.evenement.key, evenement, this.evenement.private).then(
          () => {
            this.evenementForm.reset();
            this.evenement = undefined;
          },
          error => {
            console.error('Une erreur est survenue :', error);
          }
        );
      }else{
        this.evenementService.createEvenement(evenement, evenement.private).then(
          () => {
            this.evenementForm.reset();
          },
          error => {
            console.error('Une erreur est survenue :', error);
          }
        );
      }
    }
  }

  onEdit(evenement: Evenement) {
    this.evenement = evenement;
    const dateDebutValue = new Date(evenement.dateDebut);
    const dateFinValue = new Date(evenement.dateFin);

    this.evenementForm.patchValue({
      nom: evenement.nom,
      description: evenement.description,
      dateDebut: dateDebutValue.toISOString().split('T')[0],
      heureDebut: ((dateDebutValue.getHours() < 10) ? '0' + dateDebutValue.getHours() : dateDebutValue.getHours()) + ':' + ((dateDebutValue.getMinutes() < 10) ? '0' + dateDebutValue.getMinutes() : dateDebutValue.getMinutes()),
      dateFin: dateFinValue.toISOString().split('T')[0],
      heureFin: ((dateFinValue.getHours() < 10) ? '0' + dateFinValue.getHours() : dateFinValue.getHours()) + ':' + ((dateFinValue.getMinutes() < 10) ? '0' + dateFinValue.getMinutes() : dateFinValue.getMinutes()),
      lieu: evenement.lieu,
      image: evenement.image,
      isPrivate: evenement.private
    });
  }

  onReset() {
    this.evenementForm.reset();
    this.evenement = undefined;
  }

  onDelete(evenement: Evenement) {
    if (evenement.key) {
      this.evenementService.deleteEvenement(evenement).then(
        () => {
          this.evenementForm.reset();
          this.evenement = undefined;
        },
        error => {
          console.error('Une erreur est survenue :', error);
        }
      );
    }
  }

  retrieve_evenements(){
    this.evenementService.readAllFutureEvenements().subscribe(evenements => {
      this.evenements = evenements;
    });
    Date()
  }
}
