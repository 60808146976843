import { Component, inject } from '@angular/core';
import { NewsletterService } from '../../../_services/newsletter/newsletter.service';

@Component({
  selector: 'app-newsletter',
  standalone: true,
  imports: [],
  templateUrl: './newsletter.component.html',
  styleUrl: './newsletter.component.scss'
})
export class NewsletterComponent {
  newsletterService = inject(NewsletterService);
  emails: String[] = [];

  constructor() {
    this.newsletterService.readAllNewsletters().subscribe(
      emails => {
        this.emails = emails
        console.log(emails)
      }
    )
  }

}
