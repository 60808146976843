import { Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { initCollapses, initDropdowns, initFlowbite } from 'flowbite';
import { NavbarComponent } from './_components/navbar/navbar.component';
import { FooterComponent } from './_components/footer/footer.component';
import { CommonModule, isPlatformBrowser, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AdminNavbarComponent } from './_components/admin-navbar/admin-navbar.component';
import { ToastComponent } from './_components/toast/toast.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavbarComponent, FooterComponent, AdminNavbarComponent, CommonModule, ToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  router: Router = inject(Router)
  title = 'La Bourrée de Paris';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  async ngOnInit() {
    registerLocaleData(localeFr);
    if (isPlatformBrowser(this.platformId)) {
      const AOS = (await import('aos')).default;
      AOS.init({
        duration: 1200,
        // Ajoutez d'autres options si nécessaire
      });
      try {
        initFlowbite();
        initDropdowns();
        initCollapses();
      } catch(err) {    
        console.error("Error : ", err);
      }
    }
  }

  /**
   * Vérifie si l'URL actuelle est /login ou /register
   */
  public isLoginOrRegister(): boolean {
    const url = this.router.url;
    return url === '/login' || url === '/register';
  }

  /**
   * Vérifie si l'URL actuelle est une route admin
   */
  isAdmin(): boolean {
    return this.router.url.includes('/admin');
  }
}